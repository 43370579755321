<template>
  <v-row
    class="px-10"
    dense
  >
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="user.password"
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        :type="showPassword ? 'text' : 'password'"
        label="Senha"
        placeholder="Digite uma senha"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        :type="showPassword ? 'text' : 'password'"
        :rules="[(value) => value === user.password || 'Senha não confere']"
        label="Repetir senha"
        placeholder="Repita a senha"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-select
        v-model="user.type"
        label="Tipo de perfil*"
        :rules="[() => !!user.type || 'Este campo é obrigatório']"
        :items="profiles"
        item-text="type"
        item-value="value"
        outlined
        rounded
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data: () => ({
    showPassword: false,
    profiles: [
      { type: 'Master', value: 'master' },
      { type: 'Administrador', value: 'admin' },
      { type: 'Editor de Arte', value: 'editor' },
      { type: 'Produção', value: 'production' },
    ],
  }),
}
</script>
